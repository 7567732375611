


const state = {
    cart_code: null,    
    cart_therapist: null,
    cart_timeslot: null,       
    cart_services: [
        // {
        //     id: "323sa680b32497dsfdsgga21rt47",
        //     imgUrl: require('@/assets/images/products/audio/earbuds.png'),
        //     price: 324,
        //     totalUnit: 100,
        //     details: 'Enjoy Premium Sound',
        //     title: "Steelseries Speaker Venue 8",
        //     category: "audio",
        //     brand: "Microlab",
        //     rating: 5,
        //     qty: 1,
        //     freeShipping: false,
        //     description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.`,
        //   },
        //   {
        //     id: "323sa680b324976dfgga21rt47",
        //     imgUrl: require('@/assets/images/products/audio/earphone.png'),
        //     price: 454,
        //     totalUnit: 100,
        //     details: '6G , 4k FPS',
        //     title: "Razer Speaker",
        //     category: "audio",
        //     brand: "Microlab",
        //     rating: 3,
        //     qty: 1,
        //     freeShipping: true,
        //     description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.`,
        //   },
    ], 



    cart_total_duration: null,
    cart_total_price: null,        
}

const getters = {
    getCart: state => state,
    getCartList: state => state.cart_services,        
    getCartTherapist: state => state.cart_therapist,
    getCartPrice: state => state.cart_total_price,
    getCartDuration: state => state.cart_total_duration,
    getCartTimeslot: state => state.cart_timeslot,
}

const actions = {
    addToCart({ commit, state, getters, dispatch  }, data) {
        if(state.cart_services.length==1 && state.cart_services.find(s=>s.service_type=='free60')) {
            dispatch('clearCartItems'); 
        }
        commit('ADD_CART', data)
    },

    removeFromCart({ commit }, data) {
        commit('REMOVE_CART', data)
    },

    setCart({ commit, state, getters, dispatch }, data) {        

        commit('SET_CARTCODE', data.cart_code);        
        commit('SET_CARTTHERAPIST', data.cart_therapist);      
        commit('SET_CARTTIMESLOT', data.cart_timeslot);  
        commit('CLEAR_CARTITEMS');  
        data.cart_services.forEach(element => {
            commit('ADD_CART', element);
        });
        commit('SET_CARTDURATION', data.cart_total_duration);        
        commit('SET_CARTPRICE', data.cart_total_price);                
    },
    
    setCartTherapist({ commit, state, getters, dispatch }, data) {
        if(state.cart_therapist != data) {
            dispatch('clearCartItems');                        
        }        
        commit('SET_CARTTHERAPIST', data);        
    },

    setCartTimeslot({ commit }, data) {
        commit('SET_CARTTIMESLOT', data)
    },

    clearCartItems({ commit }, data) {
        commit('CLEAR_CARTITEMS', data);
    }
}

const mutations = {

    SET_CART (state, data) {
        state = data
    },
    SET_CARTCODE (state, data) {
        state.cart_code = data
    },
    SET_CARTTHERAPIST (state, data) {
        state.cart_therapist = data
    },
    SET_CARTTIMESLOT (state, data) {
        state.cart_timeslot = data
    },
    SET_CARTDURATION (state, data) {
        state.cart_total_duration = data
    },
    SET_CARTPRICE (state, data) {
        state.cart_total_price = data
    },
    CLEAR_CARTITEMS (state, data) {
        state.cart_services.splice(0, state.cart_services.length);
    },
    REMOVE_CART (state, data) {
        const idxToRemove = state.cart_services.findIndex(i=>i.code==data.code);
        state.cart_services.splice(idxToRemove, 1);
    },
    ADD_CART (state, data) {  

        state.cart_services.push(data);
        
        // let findId = state.items.find(product => product.id == data.id)
        
        // if(findId) {
        //     data.qty += 1
           
        // }else {
        //     data.qty = 1
        //     state.cart_services.push(data)
         
        // }



        // const findId = state.addedToCart.find(product => product.id == data.id)
        // if (findId) {
        //     // console.log(findId)
        //     state.totalCart += data.productPrice
        //     data.qty += 1
        //     // console.log(data);
        // } else {
        //     state.totalCart += data.productPrice
        //     data.qty = 1
        //     state.addedToCart.push(data)
        // }
    },
    

}
export default {
    state,
    getters,
    actions,
    mutations,
}


