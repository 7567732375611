import { HTTPAPI } from "./base";
import * as qs from 'qs';


const grabToken = () => {
    const uInfoTxt = localStorage.getItem("userInfo");    
    let uInfo = null;
    if(uInfoTxt) {
        uInfo = JSON.parse(uInfoTxt);
    }
    const token = uInfo != null ? uInfo.token : "";    
    return token;
}

function composePath(path, urlParams=null, routeParams=null) {
    if(routeParams) {
        for(let key of Object.keys(routeParams)) {
            path = path.replace(key, routeParams[key]);
        }
    }    
    let finalPath = "/" + path;
    if(urlParams) {
        if(typeof urlParams === 'string') {
            finalPath += "?" + encodeURIComponent(urlParams);
        }
        else {
            finalPath += "?" + encodeURIComponent(qs.stringify(urlParams));
        }        
    }     
    return finalPath;
}

export const srvApi = {
    get: (path, urlParams=null, routeParams=null) => {                
        return HTTPAPI(grabToken())
        .get(composePath(path, urlParams, routeParams));
    },
    post: (path, data, urlParams=null, routeParams=null) => {        
        return HTTPAPI(grabToken())
        .post(composePath(path, urlParams, routeParams), data);
    },
    put: (path, data, urlParams=null, routeParams=null) => {        
        return HTTPAPI(grabToken())
        .put(composePath(path, urlParams, routeParams), data);
    },
    patch: (path, data, urlParams=null, routeParams=null) => {        
        return HTTPAPI(grabToken())
        .patch(composePath(path, urlParams, routeParams), data);
    },
    delete: (path, urlParams=null, routeParams=null) => {        
        return HTTPAPI(grabToken())
        .delete(composePath(path, urlParams, routeParams));
    },
    postfile: (path, data, onUploadProgress=null, urlParams=null, routeParams=null) => {        
        return HTTPAPI(grabToken())
        .post(
            composePath(path, urlParams, routeParams), 
            data,
            {
                headers: {
                  "Content-Type": "multipart/form-data"
                },
                onUploadProgress
            }
        );
    },
}

export const srvApiNoAuth = {
    get: (path, urlParams=null, routeParams=null) => {        
        return HTTPAPI()
        .get(composePath(path, urlParams, routeParams));
    },
    post: (path, urlParams=null) => {        
        return HTTPAPI()
        .post(composePath(path, urlParams, routeParams));
    },
    put: (path, urlParams=null) => {        
        return HTTPAPI()
        .put(composePath(path, urlParams, routeParams));
    },
    patch: (path, urlParams=null) => {        
        return HTTPAPI()
        .patch(composePath(path, urlParams, routeParams));
    },
    delete: (path, urlParams=null) => {        
        return HTTPAPI()
        .delete(composePath(path, urlParams, routeParams));
    },
    postfile: (path, data, onUploadProgress=null, urlParams=null, routeParams=null) => {        
        return HTTPAPI()
        .post(
            composePath(path, urlParams, routeParams), 
            data,
            {
                headers: {
                  "Content-Type": "multipart/form-data"
                },
                onUploadProgress
            }
        );
    },
}

export const PATH = {
    PROFILE_MY: "profile/my",
    PROFILE_MYTHERAPIST: "profile/mytherapist",
    PROFILE_MYSERVICES: "profile/myservices",
    UPLOADS_UPLOAD: "uploads/uploadasset",
    UPLOADS_UPLOADPROFILEPIC: "uploads/uploadprofilepic",
    BOOKING_THERAPISTS: "booking/therapists",
    BOOKING_THERAPIST: "booking/therapist",
    BOOKING_CART: "booking/cart",
    BOOKING_MYPROFILE: "booking/myprofile",
    PROFILE_ADDLOCATION: "profile/addlocation"
}

export const getAssetUrl = function(fileid, thumbnailOrParams) {
    //https://adminpanel.massageapp.gr/assets/c38adf71-f8b7-4a17-b377-36abcc634fc7
    //api/assets/
    let url = "/api/assets/" + fileid; // + "/" + fileid + ".png";
    if(thumbnailOrParams) {
        if(thumbnailOrParams.toString() == "true") {
            url += "";
        }
        else {
            url += thumbnailOrParams
        }
    }    
    return url; 
};